<template>
  <div>
    <component-layout :title="'JOB Types'">
      <template #menu>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="create" v-ripple="{ center: true }">
              ADD
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <v-dialog v-model="showAddModal" max-width="800px">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <create @reload-resource="reloadResource" @cancel="cancel" />
            </div>
          </div>
        </div>
      </v-dialog>
      <table class="table" style="width: 100%">
        <thead>
          <tr class="table-heading">
            <th scope="col" class="text-start px-3 py-2 ">Title</th>
            <th scope="col" class="text-start px-3 py-2">Preview</th>
            <th scope="col" class="text-start px-3 py-2">Sort</th>
            <th scope="col" class="text-start px-3 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in jobTypeList.data" :key="item.id">
            <td>{{ item.title }}</td>
            <td>
              <span
                class="preview"
                :style="{
                  background: item.background_color,
                  color: item.text_color,
                }"
                >{{ item.title }}</span
              >
            </td>
            <td>{{ item.sort }}</td>
            <td>
              <button class="btn" @click="edit(item)" title="Edit">
                <i class="fa fa-pencil edit-btn"></i>
              </button>

              <button
                class="btn ml-4"
                @click="deletePopup(item)"
                title="Delete"
              >
                <i class="fa fa-trash delete-btn"></i>
              </button>
            </td>
          </tr>
          <v-dialog v-model="showEditModal" max-width="800px">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <edit
                    :id="id"
                    :item="selectedItem"
                    @reload-resource="reloadResource"
                    @cancel="cancel"
                  />
                </div>
              </div>
            </div>
          </v-dialog>

          <v-dialog v-model="showDeleteModal" v-if="id" max-width="450px">
            <deleteDialog
              resource="job-type"
              :id="id"
              @cancel="cancel"
              @reload-resource="reloadResource"
            />
          </v-dialog>
        </tbody>
      </table>
    </component-layout>
  </div>
</template>

<script>
import util from "@/util/util";
import create from "@/components/Jobs/JobTypes/create.vue";
import componentLayout from "@/components/component-layout.vue";
import edit from "@/components/Jobs/JobTypes/edit.vue";
import deleteDialog from "@/components/shared/delete-modal";

export default {
  data() {
    return {
      util,
      showAddModal: false,
      showEditModal: false,
      showDeleteModal: false,
      jobTypeList: [],
      id: null,
      selectedItem: null,
    };
  },
  components: {
    componentLayout,
    create,
    edit,
    deleteDialog,
  },
  mounted() {
    this.getJobTypeList();
    console.log(this.jobTypeList);
  },
  methods: {
    create() {
      this.showAddModal = true;
    },
    edit(item) {
      this.id = item.id;
      this.selectedItem = item;
      this.showEditModal = true;
    },

    deletePopup(item) {
      this.showDeleteModal = true;
      this.id = item.id;
    },
    getJobTypeList() {
      util.http({ url: "/job-type" }).then((res) => {
        this.jobTypeList = res.data.data;
      });
    },
    cancel() {
      this.showDeleteModal = false;
      this.showEditModal = false;
      this.showAddModal = false;
      this.selectedItem = null;
      this.id = null;
    },
    reloadResource() {
      this.cancel();
      this.getJobTypeList();
    },
  },
};
</script>

<style scoped>
.table-heading {
  background-color: #1d2b58;
}
.table-heading th {
  color: #fff;
}
.preview {
  padding: 5px 20px;
}
</style>
